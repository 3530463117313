import React, { FC, useMemo } from "react";

import {
  ApolloClient as Client,
  PossibleTypesMap,
  TypePolicies,
  useApolloClient as useClient,
} from "@apollo/client";

import { ApolloProvider as Provider } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client";

const TYPE_POLICIES: TypePolicies = {
  Query: {
    fields: {
      viewer: {
        merge: true,
      },
    },
  },
};

export const POSSIBLE_TYPES: PossibleTypesMap = {
  Node: [
    "Brand",
    "Organization",
    "Product",
    "ProductExtra",
    "ProductVariant",
    "Resource",
    "User",
  ],
  Locatable: ["ProductVariant", "ProductExtra", "Brand"],
  Bookable: ["ProductVariant", "ProductExtra"],
  AvatarOwner: ["Brand", "Product", "User"],
  GalleryOwner: ["Brand", "Product", "ProductExtra", "ProductVariant"],
};

/**
 * Create an Apollo Client that can send both authenticated and anonymous
 * requests to our GraphQL API.
 */
const createClient = (): Client<NormalizedCacheObject> => {
  return new Client({
    uri: "/api/graphql",
    cache: new InMemoryCache({
      typePolicies: TYPE_POLICIES,
      // TODO: Figure out how to generate this from `schema.json`.
      possibleTypes: POSSIBLE_TYPES,
    }),
  });
};

export type ApolloClient = Client<NormalizedCacheObject>;

export const useApolloClient = useClient as () => ApolloClient;

export const ApolloProvider: FC = ({ children }) => {
  const client = useMemo(() => createClient(), []);
  return <Provider client={client}>{children}</Provider>;
};
