import React, { FC, useEffect, useState } from "react";
import IFrameResizer from "iframe-resizer-react";

import { Helmet } from "react-helmet";

import { Box, Center } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";

import { ChakraProvider } from "services/chakra";
import { ApolloProvider } from "services/apollo";

const App: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [slug, setSlug] = useState<string>();
  const [host, setHost] = useState<string>();
  const [text, setText] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const triggers = document.getElementsByClassName(
        "community-travel-co booking-modal-trigger",
      );
      const handlers: EventListener[] = [];

      console.info("[doorman]: plugin loaded");
      if (!triggers.length) {
        console.warn("[doorman]: no triggers found");
      }

      for (let i = 0; i < triggers.length; i++) {
        const element = triggers[i] as HTMLElement;
        const { slug: slugData, host } = element.dataset;
        const slugAttribute = element.attributes.getNamedItem("slug")
          ?.textContent;
        const slug = slugData ?? slugAttribute;

        handlers[i] = event => {
          if (slug) {
            event.preventDefault();
            setSlug(slug);
            setHost(host);
            setText(element.textContent ?? undefined);
            onOpen();
            console.debug(`[doorman]: trigger clicked`, { slug, host });
          } else {
            console.warn(`[doorman]: broken trigger clicked`);
          }
        };
        element.addEventListener("click", handlers[i]);
        console.info(`[doorman]: registered trigger`, {
          slug,
          host,
          element,
        });
      }

      return () => {
        for (let i = 0; i < triggers.length; i++) {
          triggers[i].removeEventListener("click", handlers[i]);
        }
      };
    }, 500);
  }, [onOpen]);

  return (
    <ApolloProvider>
      <ChakraProvider>
        <Helmet>
          <link rel="preconnect" href="//fonts.gstatic.com" />
          <link
            href="//fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setSlug(undefined);
            setHost(undefined);
            setText(undefined);
            setIsLoading(true);
          }}
        >
          <ModalOverlay className="community-travel-co booking-modal overlay" />
          <ModalContent
            className="community-travel-co booking-modal content"
            overflow="hidden"
            bg="gray.100"
            maxWidth="448px"
          >
            <ModalHeader pos="static" zIndex="auto">
              {text || <>&nbsp;</>}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody className="community-travel-co booking-modal body" p={0}>
              <Box pos="relative" minH={60}>
                <IFrameResizer
                  className="community-travel-co booking-modal iframe"
                  src={`${
                    host || "https://book.playces.in"
                  }/embed/booking/${slug}`}
                  sandbox="allow-top-navigation allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{ width: "100%" }}
                  onLoad={() => setIsLoading(false)}
                />
                {isLoading && (
                  <Center
                    className="community-travel-co booking-modal loading-container"
                    pos="absolute"
                    inset={0}
                    pointerEvents="none"
                    sx={{
                      backdropFilter: "blur(2px)",
                    }}
                  >
                    <Spinner
                      className="community-travel-co booking-modal loading"
                      size="lg"
                      color="blue.600"
                    />
                  </Center>
                )}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </ApolloProvider>
  );
};

export default App;
