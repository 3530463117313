import React from "react";
import ReactDOM from "react-dom";

import App from "./app";
// import reportWebVitals from "./reportWebVitals";

const mount = () => {
  if (!document.getElementById("community-travel-co-doorman")) {
    const container = document.createElement("div");
    container.id = "community-travel-co-doorman";
    container.className = "community-travel-co doorman";
    document.body.appendChild(container);
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      container,
    );
  }
};

if (document.body) {
  mount();
} else {
  window.addEventListener("load", () => {
    mount();
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
